<template>
  <div class="company-profile" @click="handleClick">
    <img :src="image" alt="Firmenbild" class="company-image" loading="lazy"/>
    <div class="company-content">
      <h1>{{ name }}</h1>
      <p class="subheading">{{ location }} | {{ industry }}</p>
      <p>{{ description }}</p>
      <ul class="company-details">
        <li v-for="detail in details" :key="detail">{{ detail }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyProfileItem',
  props: {
    name: String,
    industry: String,
    location: String,
    description: String,
    details: Array,
    image: String,
    route: String
  },
  methods: {
    handleClick() {
      this.$emit('navigate', this.route);
    }
  }
}
</script>

<style scoped>
.company-profile {
  max-width: 22.5%; /* Begrenzt die Breite des Profils auf maximal 25% des Bildschirms */
  margin: 0 auto; /* Zentriert das Profil im Elternelement */
  cursor: pointer; /* Zeigt an, dass die Items klickbar sind */
  transition: transform 0.3s ease; /* Glatter Übergang für Hover-Effekt */
}

.company-image {
  width: 100%; /* Bild nimmt die verfügbare Breite des Containers ein */
  height: auto; /* Höhe passt sich proportional zur Breite an */
}

.company-content {
  text-align: left; /* Text linksbündig ausrichten */
  background-color: #ffffff; /* Hintergrundfarbe des Textcontainers */
  padding: 20px; /* Innenabstand des Textcontainers */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Schatten für den Textcontainer */
}

.company-content h1 {
  font-size: 1.2em; /* Größe der Überschrift */
  margin-bottom: 0.2em; /* Abstand unter der Überschrift */
}

.subheading {
  font-weight: normal; /* Standard Schriftstärke */
  color: #666; /* Dunkelgrau für den Untertitel */
  margin-bottom: 0.5em; /* Abstand unter dem Untertitel */
}

.company-content p {
  margin-bottom: 1.5em; /* Abstand unter dem Absatz */
}

.company-details {
  padding-left: 20px; /* Abstand für Aufzählungszeichen */
  border-top: 1px solid #ccc; /* Linie oberhalb der Liste */
  padding-top: 10px; /* Abstand oberhalb der ersten Liste */
}

.company-details li {
  margin-bottom: 5px; /* Abstand zwischen Listenelementen */
  font-size: 0.8em; /* Größe der Listenelemente */
}


.company-profile:hover {
  transform: scale(1.05); /* Vergrößert das Item leicht beim Hover */
}

/* Entfernt den rechten Abstand vom letzten Item jeder Reihe */
.company-profile-item:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .company-profile {
    max-width: 45%; /* Erhöht die Breite für Tablet-Ansichten */
  }
}

@media (max-width: 768px) {
  .company-profile {
    max-width: 100%; /* Erhöht die Breite für mobile Ansichten */
    margin-top: 20px; /* Fügt einen oberen Abstand zwischen den Profilen hinzu */
  }

}

/* Weitere Anpassungen für sehr kleine Bildschirme */
@media (max-width: 480px) {
  .company-profile {
    /* Anpassungen für kleinere Bildschirme */
    max-width: 100%; /* Profil nimmt die volle Breite ein */
    margin-top: 20px; /* Fügt einen oberen Abstand zwischen den Profilen hinzu */
  }
}
</style>
