<template>
  <div class="home">
    <div class="image-container">
        <div class="image-overlay">
            <img src="../assets/Zurich_start.jpg" />
        </div>
      <div class="overlay-content">
        <div class="text-overlay">
          Wir unterstützen Sie mit Ideen: Kostenloser Einblick in Small- und Midcap-Unternehmen.
        </div>
        <button class="cta-button" @click="goToRoute('/research')">Zum Research</button>
        <br>
        <div class="content-row">
          <div class="content-block">
            <h2>Gelegentlich</h2>
            <p>Qualität statt Quantität. Wir veröffentlichen Analysen, wenn wir eine Gelegenheit am Markt entdecken, von der wir überzeugt sind.</p>
          </div>
          <div class="content-block">
            <h2>Kostenlos</h2>
            <p>Unser Research wird unabhängig durchgeführt und kostenlos veröffentlicht.</p>
          </div>
          <div class="content-block">
            <h2>Ideengeneration</h2>
            <p>Wir sehen uns als Ideengenerator, nicht als Anlageberater.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//  <img src="../assets/pexels-trace-hudson-7369495.jpg" />
export default {
  name: 'SiteFooter',
  // Weitere Optionen und Methoden können hier definiert werden
  methods: {
    goToRoute(route) {
      this.$router.push(route);
    }
  }
}
</script>

<style scoped>
.home {
  width: 100%;
  height: auto; /* Höhe ist abhängig vom Inhalt */
}

.image-container {
  position: relative;
  width: 100%;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 80%; /* Overlay nimmt die volle Breite ein */
  z-index: 2;
}

.text-overlay {
  font-size: 3em;
  font-weight: bold;
}

.cta-button {
  display: inline-block;
  margin-top: 20px;
  padding: 8px 16px;
  border: 2px solid #ffffff;
  color: #ffffff;
  background-color: transparent;
  border-radius: 4px;
  font-size: 0.8em; /* Angepasste Schriftgröße entsprechend Ihrem Design */
  cursor: pointer;
  transition: all 0.3s ease;
  
}

.cta-button:hover {
  background-color: #ffffff;
  color: #000000;
}

.content-row {
  display: flex;
  justify-content: center; /* Zentriert die Blöcke horizontal */
  margin-top: 100px; /* Abstand oben und unten */
}

.content-block {
  width: 33%; /* Gleichmäßige Aufteilung der Blöcke */
  margin: 0 10px; /* Horizontaler Abstand zwischen den Blöcken */
  position: relative;
}

.content-block h2 {
  font-size: 1.2em; /* Größere Schrift für Überschriften */
  margin-bottom: 0.5em; /* Abstand unter den Überschriften */
  margin-top: 10px;
}

.content-block p {
  font-size: 0.9em; /* Schriftgröße für Absätze */
}

.content-block:before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%; /* Positioniert die Linie über dem Content-Block */
  width: 80%; /* oder die gewünschte Breite */
  height: 2px; /* oder die gewünschte Dicke */
  background-color: white; /* oder die gewünschte Farbe */
}

.content-block:after {
  bottom: auto;
  top: 100%; /* Positioniert die Linie unter dem Content-Block */
}


.image-overlay:before{
content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Schwarze Überlagerung mit 50% Deckkraft */
  z-index: 1;
}

@media (max-width: 768px) {
  .image-container img {
    /* Zoom-Effekt auf das Bild anwenden */
    transform: scale(1.2);
    /* Zentrieren des Bildes nach dem Zoom */
    object-position: center center;
  }

  .overlay-content {
    top: 30%; /* Anpassung für kleinere Bildschirme */
  }

  .content-row {
    flex-direction: column;
  }

  .content-block {
    width: 80%; /* Breitere Blöcke für kleinere Bildschirme */
    margin-bottom: 20px; /* Vertikaler Abstand zwischen den Blöcken */
  }
}

/* ... vorhandener Code ... */
@media (max-width: 768px) {
  .image-container {
    max-height: 800px; /* Legt die maximale Höhe des Bildcontainers fest */
    overflow: hidden; /* Verhindert, dass der Inhalt über den Container hinausgeht */
  }

  .image-container img {
  width: auto; /* Setzen Sie die Breite auf auto, damit das Bild seine ursprüngliche Breite beibehält */
  height: 100%; /* Setzen Sie die Höhe auf 100% des Containers */
  min-width: 100%; /* Stellen Sie sicher, dass das Bild mindestens die Breite des Containers ausfüllt */
  transform: scale(1.2); /* Skalieren des Bildes, um den Zoom-Effekt zu erzeugen */
  transform-origin: center center; /* Setzen des Ursprungs der Transformation */
}

  .overlay-content {
    /* Positionierung des Inhalts über dem Bild */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    padding: 0 5%;
  }


  .text-overlay {
    /* Reduziert die Schriftgröße für mobile Geräte */
    font-size: 1.5em; /* Beispielwert, an Ihre Bedürfnisse anpassen */
  }

  .cta-button {
    /* Vergrößert die Schaltfläche für bessere Touch-Interaktion */
    padding: 12px 24px; /* Beispielwerte, an Ihre Bedürfnisse anpassen */
  }

  .content-row {
    /* Ändert die Flex-Richtung, um Blöcke untereinander zu stapeln */
    flex-direction: column;
  }

  .content-block {
    /* Stellt sicher, dass jeder Block die volle Breite nutzt */
    width: 100%;
    margin: 0; /* Entfernt den horizontalen Abstand */
    margin-bottom: 15px; /* Fügt vertikalen Abstand hinzu */
  }
}

</style>