<template>
  <div class="liste">
    <h1>Aktuellste Analysen</h1>
    <div class="profiles-container">
      <CompanyProfileItem
        v-for="profile in companyProfiles"
        :key="profile.name"
        :name="profile.name"
        :industry="profile.industry"
        :location="profile.location"
        :description="profile.description"
        :details="profile.details"
        :image="profile.image"
        @click.native="goToRoute(profile.route)"
      />
    </div>
  </div>
</template>

<script>
import CompanyProfileItem from './item_research.vue';

export default {
  name: 'Item-Liste',
  components: {
    CompanyProfileItem
  },
  data() {
    return {
      companyProfiles: [
        {
          name: 'Mo-BRUK S.A.',
          industry: 'Waste Management',
          location: 'Korzenna / Polen',
          description: 'Das Polnische WM?',
          details: ['Long', 'Value', '42% upside potential'],
          image: require('@/assets/waste.jpg'),
          route: '/mobruk'
        },
        {
          name: 'Occidental Petroleum',
          industry: 'Oil&Gas / Chemie',
          location: 'Houston / USA',
          description: 'Öl, das schwarze Gold oder ein schwarzes Loch?',
          details: ['Long', 'Value', '16% upside potential'],
          image: require('@/assets/oxy_oil.jpg'),
          route: '/oxy'
        },
        {
          name: 'Wirtschaftsausblick Mexiko',
          industry: 'Makroökonomische Analyse',
          location: 'Mexiko',
          description: 'Tequila, Tacos und... Inflation',
          details: ['Neutral', 'Spekulation'],
          image: require('@/assets/mexiko.jpg'),
          route: '/research'
        },


      ]
    };
  },
  methods: {
    goToRoute(route) {
      this.$router.push(route);
    }
  }
}
</script>

<style>
.liste {
  text-align: center;
  padding: 20px;
}

.liste h1 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 50px;
}

.profiles-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

/* ... vorhandene Stile ... */

@media (max-width: 768px) {
  .profiles-container {
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    gap: 0;
  }
}

@media (max-width: 480px) {
  .profiles-container {
    padding: 0 5%;
  }
}

</style>