<template>
  <footer class="site-footer">
    <div class="footer-container">
      <!-- Linke Spalte -->
      <div class="footer-column">
        <h3 class="footer-title">Limmat Equity Analytics</h3>
        <ul class="footer-links">
          <li><router-link to="/" class="footer-nav-link">Home</router-link></li>
          <li><router-link to="/research" class="footer-nav-link">Research</router-link></li>
          <li><router-link to="/about" class="footer-nav-link">Über uns</router-link></li>
        </ul>
        <div class="social-icons">
          <a href="https://www.linkedin.com/company/limmat-equity-analytics" target="_blank"><img class="icon" src="../assets/linkedin.png" /></a>
          <a href="https://www.twitter.com" target="_blank"><img class="icon" src="../assets/twitter.png" /></a>
          <a href="mailto:info@limmatanalytics.com"><img class="icon" src="../assets/email.png" /></a>
        </div>
      </div>
      <!-- Mittlere Spalte -->
      <div class="footer-column">
        <h3 class="footer-title">Rechtliches</h3>
        <ul class="footer-links">
          <li><router-link to="/imprint" class="footer-link-underline">Impressum</router-link></li>
          <li><router-link to="/privacy-policy" class="footer-link-underline">Datenschutzvereinbarung</router-link></li>
        </ul>
      </div>
      <!-- Rechte Spalte -->
      <div class="footer-column">
        <h3 class="footer-title">Kontakt</h3>
        <p>Thomas Neumann </p>
        <p>Bürglistrasse 23</p>
        <p>8400 Winterthur</p>
        <br>
        
        <p>info@limmatanalytics.com</p>
      </div>
    </div>
  </footer>
</template>

<script>
//  <p>+41 77 2663182</p>
export default {
  name: 'SiteFooter',
  // Weitere Optionen und Methoden können hier definiert werden
}
</script>

<style scoped>
.site-footer {
  background-color: #081c3c;
  color: white;
  padding: 40px 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  margin-top: 80px;
}

.footer-container {
  display: flex;
  justify-content: space-evenly;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer-title {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #4A4A4A;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin-bottom: 20px; /* Abstand für die Icon-Platzhalter */
  text-align: left;
}

.footer-links li {
  margin-bottom: 10px;
  text-align: left;
}

.social-icons {
  /* Diese Klasse kann weiter angepasst werden, um tatsächliche Icons hinzuzufügen */
}

.icon-placeholder {
  display: inline-block;
  margin-right: 10px;
  /* Stellen Sie hier das tatsächliche Icon ein, z.B. mit einer Schriftart oder einem Bild */
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 20px;
  }
}

.icon{
    width: 40px;
    margin-right: 10px;
}
.footer-nav-link {
  text-decoration: none; /* Entfernt die Standard-Unterstreichung */
  color: white; /* Weiße Textfarbe für bessere Lesbarkeit */
}

.footer-nav-link:hover {
  text-decoration: underline; /* Fügt Unterstreichung beim Hover hinzu */
}

.footer-link-underline {
  text-decoration: underline; /* Dauerhafte Unterstreichung */
}

.social-icons a {
  margin-right: 10px; /* Abstand zwischen den Icons */
}

.social-icons a:last-child {
  margin-right: 0; /* Kein Abstand zum rechten Rand */
}

.icon {
  width: 40px; /* Größe der Icons */
  transition: opacity 0.3s ease; /* Weiche Übergang für Hover-Effekt */
}

.icon:hover {
  opacity: 0.7; /* Verringert die Deckkraft beim Hover */
}

/* Bestehende Stile ... */

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    padding: 0 10%; /* Fügt seitlichen Abstand hinzu */
  }

  .footer-column {
    width: 100%; /* Lässt die Spalten die volle Breite einnehmen */
    margin-bottom: 20px; /* Fügt Abstand zwischen den Spalten hinzu */
  }

  .footer-title {
    font-size: 1.2em; /* Erhöht die Schriftgröße der Titel */
  }

  .footer-links li, .subheading, .footer-nav-link, .footer-link-underline {
    font-size: 1em; /* Passt die Schriftgröße der Textelemente an */
  }

  .icon {
    width: 30px; /* Passt die Größe der Icons an */
    margin-right: 5px; /* Verringert den Abstand zwischen den Icons */
  }

  .social-icons a {
    margin-right: 5px; /* Verringert den Abstand zwischen den Icons */
  }

  .social-icons a:last-child {
    margin-right: 0; /* Entfernt den Abstand zum rechten Rand für das letzte Icon */
  }
}

/* Anpassungen für sehr kleine Bildschirme */
@media (max-width: 480px) {
  .footer-container {
    padding: 0 5%; /* Verringert den seitlichen Abstand weiter */
  }

  /* Weitere individuelle Anpassungen können hier hinzugefügt werden */
}

</style>
