
<template>
<div>
  <home_one />
  <home_two />
  <home_three />
  <home_four />
  <home_five />
  <home_six />
  <h1 style="visibility: collapse">Limmat Equity Analytics</h1>
</div>
</template>

<script>
import home_one from '@/components/home_one.vue'
import home_two from '@/components/home_two.vue'
import home_three from '@/components/home_three.vue'
import home_four from '@/components/home_four.vue'
import home_five from '@/components/home_five.vue'
import home_six from '@/components/home_six.vue'

export default {
  name: 'HomePage',
  components: { home_one, home_two, home_three, home_four, home_five, home_six },

  metaInfo: {
    // Setzt den Titel der Seite
    title: 'Limmat Equity Analytics - Aktienanalysen zur Ideengenerierung',
    // Setzt Meta Tags für die Seite
    meta: [
      {
        name: 'description',
        content: 'Limmat Analytics bietet kostenlose Aktienanalysen, um Ihnen zu helfen, Ideen zu generieren. Entdecken Sie jetzt tiefgreifende Einblicke in die Märkte.'
      },
      {
        property: 'og:title',
        content: 'Limmat Equity Analytics - Aktienanalysen zur Ideengenerierung'
      },
      {
        property: 'og:description',
        content: 'Limmat Analytics bietet kostenlose Aktienanalysen, um Ihnen zu helfen, Ideen zu generieren. Entdecken Sie jetzt tiefgreifende Einblicke in die Märkte.'
      }
      // Hier können weitere Meta-Tags hinzugefügt werden, wie z.B. og:image für Bilder, Twitter Cards etc.
    ]
  }
}
</script>
