<template>
  <div class="header-bar">
    <router-link to="/" class="logo-link">
      <img src="../assets/Logo_mit_Name-removebg-preview.png" alt="Logo" class="logo" />
    </router-link>
    <div class="nav-toggle" @click="toggleMenu" v-if="isMobile">&#9776;</div>
    <div class="nav-links" :class="{'is-visible': menuVisible}" v-if="isMobile">
      <router-link to="/research" class="nav-link">Research</router-link>
      <router-link to="/about" class="nav-link">About</router-link>
    </div>
    <div class="nav-links desktop" v-else>
      <router-link to="/research" class="nav-link">Research</router-link>
      <router-link to="/about" class="nav-link">About</router-link>
    </div>
  </div>
</template>

<script>
//       <router-link to="/portfolio" class="nav-link">Portfolio</router-link>
//      <router-link to="/portfolio" class="nav-link">Portfolio</router-link>
export default {
  name: 'HeaderBar',
  data() {
    return {
      menuVisible: false,
      isMobile: window.innerWidth < 768,
    };
  },
  mounted() {
    window.addEventListener('resize', this.updateMobileStatus);
    this.updateMobileStatus();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMobileStatus);
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    updateMobileStatus() {
      this.isMobile = window.innerWidth < 768;
    }
  }
}
</script>

<style scoped>
.header-bar {
  background-color: #081c3c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
}

.logo {
  max-height: 1.7cm; /* Verkleinern des Logos */
  max-width: 7.5cm;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 40px;
  color: white;
}

.nav-toggle {
  display: none; /* Standardmäßig versteckt */
  font-size: 2.5rem; /* Vergrößert das Hamburger-Icon */
  cursor: pointer;
}
/* ... Vorherige Stile beibehalten ... */

@media (max-width: 768px) {
  .header-bar {
    flex-direction: column; /* Stapelt die Elemente vertikal */
    align-items: flex-start; /* Ausrichtung am Anfang (links) */
  }

  .logo {
    max-height: 1.2cm; /* Anpassung der Höhe des Logos */
    max-width: 6cm; /* Anpassung der Breite des Logos */
    height: auto; /* Höhe automatisch anpassen */
    order: 1; /* Stellt das Logo an den Anfang */
    margin-bottom: 0.5rem; /* Reduzierter Abstand nach unten zum Hamburger-Icon */
  }

  .nav-toggle {
    display: block; /* Stellt sicher, dass das Hamburger-Icon angezeigt wird */
    position: absolute; /* Absolut positioniert */
    color: white;
    top: 0rem; /* Abstand von oben */
    right: 2rem; /* Abstand von rechts */
    order: 2; /* Stellt den Hamburger-Icon nach dem Logo */
  }

  .nav-links {
    display: none; /* Versteckt die Links standardmäßig */
    width: 100%; /* Vollbreite */
    flex-direction: column; /* Elemente vertikal stapeln */
    align-items: flex-start; /* Ausrichtung am Start (links) */
    order: 3; /* Platziert die Links unter dem Logo und Hamburger-Icon */
    padding-left: 1rem; /* Linker Innenabstand */
  }

  .nav-links.is-visible {
    display: flex; /* Zeigt die Links als Flexbox */
  }

  .nav-link {
    padding-top: 0px; /* Verringertes vertikales Padding für jeden Link */
    text-align: left; /* Textausrichtung links */
    width: 100%; /* Stellt sicher, dass der Link die volle Breite einnimmt */
    box-sizing: border-box; /* Padding und Border werden in der Breite eingeschlossen */
  }
}

/* ... Weiterer CSS-Code ... */


</style>
