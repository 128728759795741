<template>
  <div class="statistics-container">
    <div class="statistic">
      <h2>coming soon</h2>
      <p>Trefferquote</p>
      <span>Die Analysen zeigten innerhalb von drei Monaten eine Entwicklung in die prognostizierte Richtung.</span>
    </div>
    <div class="statistic">
      <h2>3</h2>
      <p>Analysen</p>
      <span>und wöchentlich weitere</span>
    </div>
    <div class="statistic">
      <h2>coming soon</h2>
      <p>Average Movement</p>
      <span>Durschnittliche Bewegung der vorgestellten Werte innerhalb von 3 Monaten</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticsComponent'
}
</script>

<style scoped>
.statistics-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  text-align: center;
  padding: 50px 10%; /* Seitlicher Abstand für den gesamten Container */
}

.statistic {
  max-width: 25%;
  box-sizing: border-box;
}

.statistic h2 {
  font-size: 2.5rem; /* oder die gewünschte Größe */
  margin-bottom: 0.5rem;
  word-wrap: break-word; /* Erzwingt einen Zeilenumbruch für lange Wörter */
}

.statistic p {
  font-size: 1.5rem; /* oder die gewünschte Größe */
  margin-bottom: 0.5rem;
  word-wrap: break-word; /* Erzwingt einen Zeilenumbruch für lange Wörter */
}

.statistic span {
  display: block; /* um sicherzustellen, dass der Text in einer neuen Zeile beginnt */
  font-size: 1rem; /* oder die gewünschte Größe */
  color: #666; /* oder die gewünschte Textfarbe */
  word-wrap: break-word; /* Erzwingt einen Zeilenumbruch für lange Wörter */
}
.statistic h2 {
  /* Ihre bestehenden h2-Stile bleiben gleich */
  position: relative; /* Für die Pseudo-Elemente */
}

.statistic h2:after {
  content: '';
  display: block;
  width: 200px; /* Fixierte Breite der Linie */
  height: 2px; /* Dicke der Linie */
  background-color: #000; /* Farbe der Linie */
  margin: 8px auto; /* Zentriert die Linie horizontal */
  /* Abstand nach unten zum Paragraphen */
}

/* Media Queries für responsives Design */
@media (max-width: 768px) {
  .statistic {
    max-width: 50%;
    margin-bottom: 20px;
    padding: 0 10px; /* Seitlicher Abstand für jeden Statistikblock */
  }
}

@media (max-width: 480px) {
  .statistic {
    max-width: 100%;
    padding: 0; /* Kein seitlicher Abstand für sehr kleine Bildschirme */
  }
}
</style>
