import Vue from 'vue'
import App from './App.vue'
import router from './router'
import HeaderBar from './views/HeaderBar.vue';
import footer_bar from './components/footer_bar.vue'

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta';
import cookieconsent from 'vue-cookieconsent-component'

Vue.component('cookie-consent', cookieconsent)
Vue.use(BootstrapVue)
Vue.component('HeaderBar', HeaderBar);
Vue.component('footer_bar', footer_bar);

Vue.config.productionTip = false

Vue.use(VueMeta)

Vue.use(VueGtag, {
  config: {
    id: 'G-DKR8M5DMS6'
  },
  enabled: true,
}, router)

new Vue({
  router,
  BootstrapVue,
  render: function (h) { return h(App) }
}).$mount('#app')

