<template>
  <div class="image-section">
    <div class="image-overlay"></div> <!-- Hinzugefügte Überlagerung -->
    <div class="content">
      <h2>Als Portfolio Manager auf der Suche nach gezielten Unternehmensanalysen?</h2>
      <p>Suchen Sie als Portfolio Manager einen Sparringpartner? Ich biete Ihnen eine kostenlose, maßgeschneiderte Analyse im Gegenzug für konstruktives Feedback. Kontaktieren Sie mich gerne via E-Mail oder LinkedIn!</p>
      <button @click="contact">Email</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageOverlaySection',
  methods: {
    contact() {
      // Logik für Kontaktierung, z.B. Weiterleitung zu einer Kontaktseite oder Öffnen eines Mail-Programms
      console.log('Kontaktieren Sie uns!');
    }
  }
}
</script>

<style scoped>
.image-section {
  position: relative;
  width: 90%;
  max-width: 1200px;
  height: 500px;
  margin: 0 auto;
  border-radius: 15px;
  background-image: url('@/assets/zurich.jpeg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 100px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dunkle Überlagerung, Deckkraft anpassen für mehr oder weniger Abdunkelung */
  border-radius: 15px; /* Abgerundete Ecken der Überlagerung */
  z-index: 1; /* Stellt sicher, dass die Überlagerung unter dem Inhalt ist */
}

.content {
  position: relative;
  z-index: 2; /* Stellt sicher, dass der Inhalt über der Überlagerung ist */
  text-align: center;
  color: white;
  padding: 20px; /* Innerer Abstand */
  max-width: 800px; /* Maximale Breite des Inhalts */
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #eaeaea;
}
</style>
