<template>
  <div class="image-container">
    <div class="image">
      <img src="../assets/picture_two_size.png" alt="Maßgeschneiderter Research" loading="lazy"/>
    </div>
    <div class="image">
      <img src="../assets/picture_one.png" alt="Einzelwerte im Rampenlicht" loading="lazy"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImagePair'
}
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px; /* Abstand zwischen den Bildern */
}

.image {
  flex: 1; /* Beide Bilder erhalten die gleiche Flex-Basis */
  max-width: 35%; /* Maximale Breite jedes Bildcontainers */
}

.image img {
  width: 100%; /* Die Bilder füllen den jeweiligen Container aus */
  height: auto; /* Höhe wird automatisch angepasst */
  object-fit: cover; /* Passt die Bilder im Container ohne Verzerrung an */
}

@media (max-width: 768px) {
  .image-container {
    flex-direction: column; /* Stapelt die Bilder vertikal */
    gap: 20px; /* Kleinerer Abstand zwischen den Bildern für mobile Ansicht */
    margin: 0 10%; /* 10% seitlicher Abstand */
  }

  .image {
    max-width: 100%; /* Erlaubt den Bildern, die volle Breite zu nutzen */
  }
}
</style>

