<template>
  <section class="project-description">
    <h1>Das Projekt</h1>
    <p>
      Begonnen als Hobby und Studienprojekt, präsentiert Limmat Equity Analytics nun gelegentlich
      fundierte Research-Studien zu internationalen Small- und Midcap-Unternehmen. LEA versteht sich als
      Quelle inspirierender Ideen, nicht als Anlageberatung. Das primäre Ziel dieses Projekts ist die
      Weiterentwicklung meiner Analysefähigkeiten, der Aufbau von Industriekontakten sowie die Sammlung
      praktischer Erfahrungen in den Bereichen Branding und Startup-Management.
    </p>
  </section>
</template>

<script>
export default {
  name: 'ProjectDescription',
}
</script>

<style scoped>
.project-description {
  text-align: center;
  padding: 100px 20px; /* Angepasster Abstand oben und unten */
  background-color: #fff; /* oder jede gewünschte Hintergrundfarbe */
  color: #000; /* Textfarbe */
}

.project-description h1 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 30px; /* Abstand zwischen H1 und Paragraph */
}

.project-description p {
  font-size: 1.25em; /* Passen Sie die Schriftgröße nach Bedarf an */
  max-width: 900px; /* Maximalbreite für den Absatz */
  margin-left: auto; /* Zentriert den Paragraphen horizontal */
  margin-right: auto;
}

/* Mobile Ansicht */
@media (max-width: 768px) {
  .project-description {
    padding: 50px 10%; /* Verkleinerter Abstand und prozentuale Angabe für den seitlichen Abstand */
  }

  .project-description h1 {
    font-size: 1.5em; /* Kleinere Schriftgröße für H1 */
  }

  .project-description p {
    font-size: 1em; /* Kleinere Schriftgröße für Paragraph */
    text-align: justify; /* Stellt den Text auf Blocksatz ein */
    hyphens: auto; /* Fügt automatische Silbentrennung hinzu */
  }
}

</style>
